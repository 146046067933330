// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-access-js": () => import("./../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-dosing-js": () => import("./../src/pages/dosing.js" /* webpackChunkName: "component---src-pages-dosing-js" */),
  "component---src-pages-getstarted-js": () => import("./../src/pages/getstarted.js" /* webpackChunkName: "component---src-pages-getstarted-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-palforziaoverview-js": () => import("./../src/pages/palforziaoverview.js" /* webpackChunkName: "component---src-pages-palforziaoverview-js" */),
  "component---src-pages-palisade-js": () => import("./../src/pages/palisade.js" /* webpackChunkName: "component---src-pages-palisade-js" */),
  "component---src-pages-ramses-js": () => import("./../src/pages/ramses.js" /* webpackChunkName: "component---src-pages-ramses-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-safety-js": () => import("./../src/pages/safety.js" /* webpackChunkName: "component---src-pages-safety-js" */),
  "component---src-pages-sitemap-js": () => import("./../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */)
}

